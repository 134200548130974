
import {Component, Vue} from 'vue-property-decorator';
import {store} from '@/main';

@Component({})
export default class Logiciels extends Vue {
  private balladeInstallerLink: any = store.getters.balladeInstallerLink;
  private hasNetworkSDWAN: boolean = store.getters.fullUser.hasNetworkSDWAN;
  private hasNetworkLegacy: boolean = store.getters.fullUser.hasNetworkLegacy;
  private vpnAdnovSoftwareLink: string = store.getters.vpnAdnovSoftwareLink;
}
